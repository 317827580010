// src/components/TaskModal.js
import React, { useState, useEffect, useCallback } from 'react';
import { X, Plus, Trash2, RefreshCw } from 'lucide-react';
import AITaskBreakdown from './AITaskBreakdown';
import { generateTags } from '../utils/tagGenerator';
import './TaskModal.css';

const TaskModal = ({ task, onClose, onUpdate, onDelete }) => {
    const [editedTask, setEditedTask] = useState(task);
    const [newComment, setNewComment] = useState('');
    const [newChecklistItem, setNewChecklistItem] = useState('');
    const [newTag, setNewTag] = useState('');
    const [isRegeneratingTags, setIsRegeneratingTags] = useState(false);
    const [isChanged, setIsChanged] = useState(false);

    const checkForChanges = useCallback(() => {
        const hasChanged = JSON.stringify(task) !== JSON.stringify(editedTask);
        setIsChanged(hasChanged);
    }, [task, editedTask]);

    useEffect(() => {
        checkForChanges();
    }, [editedTask, checkForChanges]);

    const handleUpdate = () => {
        onUpdate(editedTask);
        setIsChanged(false);
        onClose();
    };

    const handleDelete = () => {
        onDelete(task.id);
        onClose();
    };

    const handleInputChange = (field) => (e) => {
        setEditedTask(prev => ({ ...prev, [field]: e.target.value }));
    };

    const addComment = () => {
        if (newComment.trim() !== '') {
            setEditedTask(prev => ({
                ...prev,
                comments: [...(prev.comments || []), { id: Date.now(), text: newComment }]
            }));
            setNewComment('');
        }
    };

    const addChecklistItem = () => {
        if (newChecklistItem.trim() !== '') {
            setEditedTask(prev => ({
                ...prev,
                checklist: [...(prev.checklist || []), { id: Date.now(), text: newChecklistItem, completed: false }]
            }));
            setNewChecklistItem('');
        }
    };

    const toggleChecklistItem = (id) => {
        setEditedTask(prev => ({
            ...prev,
            checklist: (prev.checklist || []).map(item =>
                item.id === id ? { ...item, completed: !item.completed } : item
            )
        }));
    };

    const deleteChecklistItem = (id) => {
        setEditedTask(prev => ({
            ...prev,
            checklist: (prev.checklist || []).filter(item => item.id !== id)
        }));
    };

    const addTag = () => {
        if (newTag.trim() !== '' && !(editedTask.tags || []).includes(newTag.trim())) {
            setEditedTask(prev => ({
                ...prev,
                tags: [...(prev.tags || []), newTag.trim()]
            }));
            setNewTag('');
        }
    };

    const removeTag = (tagToRemove) => {
        setEditedTask(prev => ({
            ...prev,
            tags: (prev.tags || []).filter(tag => tag !== tagToRemove)
        }));
    };

    const regenerateTags = async () => {
        setIsRegeneratingTags(true);
        try {
            const newTags = await generateTags(editedTask.text);
            setEditedTask(prev => ({
                ...prev,
                tags: newTags
            }));
        } catch (error) {
            console.error('Error regenerating tags:', error);
            // You might want to show an error message to the user here
        } finally {
            setIsRegeneratingTags(false);
        }
    };

    const handleAIBreakdown = (updatedTask) => {
        setEditedTask(updatedTask);
    };

    return (
        <div className="task-modal bg-white p-6 rounded-lg shadow-xl max-w-2xl w-full">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-bold text-gray-900">Edit Task</h2>
                <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                    <X className="w-6 h-6"/>
                </button>
            </div>

            <input
                type="text"
                value={editedTask.text}
                onChange={handleInputChange('text')}
                className="w-full p-2 mb-4 border rounded text-gray-800 bg-gray-100"
            />

            <textarea
                value={editedTask.description}
                onChange={handleInputChange('description')}
                placeholder="Task description"
                className="w-full p-2 mb-4 border rounded h-32 text-gray-800 bg-gray-100"
            />

            <div className="mb-4">
                <h3 className="text-lg font-semibold mb-2 text-gray-900">Tags</h3>
                <div className="flex flex-wrap mb-2">
                    {(editedTask.tags || []).map(tag => (
                        <span key={tag} className="bg-blue-100 text-blue-800 px-2 py-1 rounded mr-2 mb-2">
                            #{tag}
                            <button onClick={() => removeTag(tag)} className="ml-1 text-red-500 hover:text-red-700">
                                &times;
                            </button>
                        </span>
                    ))}
                </div>
                <div className="flex">
                    <input
                        type="text"
                        value={newTag}
                        onChange={(e) => setNewTag(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' && addTag()}
                        placeholder="Add a tag"
                        className="flex-grow p-2 border rounded-l text-gray-800 bg-gray-100"
                    />
                    <button onClick={addTag} className="bg-blue-500 text-white p-2 rounded-r hover:bg-blue-600">
                        <Plus className="w-5 h-5"/>
                    </button>
                </div>
                <button
                    onClick={regenerateTags}
                    disabled={isRegeneratingTags}
                    className={`mt-2 bg-green-500 text-white p-2 rounded hover:bg-green-600 flex items-center ${isRegeneratingTags ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                    <RefreshCw className={`w-5 h-5 mr-2 ${isRegeneratingTags ? 'animate-spin' : ''}`}/>
                    Regenerate Tags
                </button>
            </div>

            <div className="mb-4">
                <h3 className="text-lg font-semibold mb-2 text-gray-900">Checklist</h3>
                {(editedTask.checklist || []).map(item => (
                    <div key={item.id} className="flex items-center mb-2">
                        <input
                            type="checkbox"
                            checked={item.completed}
                            onChange={() => toggleChecklistItem(item.id)}
                            className="mr-2"
                        />
                        <span className={`text-gray-800 ${item.completed ? 'line-through' : ''}`}>{item.text}</span>
                        <button onClick={() => deleteChecklistItem(item.id)}
                                className="ml-auto text-red-500 hover:text-red-700">
                            <Trash2 className="w-4 h-4"/>
                        </button>
                    </div>
                ))}
                <div className="flex">
                    <input
                        type="text"
                        value={newChecklistItem}
                        onChange={(e) => setNewChecklistItem(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' && addChecklistItem()}
                        placeholder="New checklist item"
                        className="flex-grow p-2 border rounded-l text-gray-800 bg-gray-100"
                    />
                    <button onClick={addChecklistItem}
                            className="bg-blue-500 text-white p-2 rounded-r hover:bg-blue-600">
                        <Plus className="w-5 h-5"/>
                    </button>
                </div>
            </div>

            <div className="mb-4">
                <h3 className="text-lg font-semibold mb-2 text-gray-900">Comments</h3>
                {(editedTask.comments || []).map(comment => (
                    <div key={comment.id} className="mb-2 p-2 bg-gray-100 rounded text-gray-800">
                        {comment.text}
                    </div>
                ))}
                <div className="flex">
                    <input
                        type="text"
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' && addComment()}
                        placeholder="Add a comment"
                        className="flex-grow p-2 border rounded-l text-gray-800 bg-gray-100"
                    />
                    <button onClick={addComment} className="bg-green-500 text-white p-2 rounded-r hover:bg-green-600">
                        <Plus className="w-5 h-5"/>
                    </button>
                </div>
            </div>

            <AITaskBreakdown
                task={editedTask}
                onUpdateTask={handleAIBreakdown}
            />

            <div className="flex justify-between mt-6">
                <button onClick={handleDelete} className="bg-red-500 text-white p-2 rounded hover:bg-red-600">
                    Delete Task
                </button>
                <button
                    onClick={handleUpdate}
                    className={`update-button ${isChanged ? 'animate' : ''}`}
                    disabled={!isChanged}
                >
                    <span className="update-button-content">
                        Update Task
                    </span>
                </button>
            </div>
        </div>
    );
};

export default TaskModal;