// src/components/ChangeUsernameModal.js
import React, { useState } from 'react';
import { X } from 'lucide-react';

const ChangeUsernameModal = ({ onClose, onChangeUsername }) => {
    const [newUsername, setNewUsername] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (newUsername.trim() === '') {
            setError('Username cannot be empty');
            return;
        }
        try {
            await onChangeUsername(newUsername);
            onClose();
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4">
            <div className="bg-white rounded-lg p-6 w-full max-w-md">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold">Change Username</h2>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                        <X className="w-6 h-6" />
                    </button>
                </div>
                {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">{error}</div>}
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="new-username" className="block text-sm font-medium text-gray-700">New Username</label>
                        <input
                            type="text"
                            id="new-username"
                            value={newUsername}
                            onChange={(e) => setNewUsername(e.target.value)}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50"
                            required
                        />
                    </div>
                    <button type="submit" className="w-full bg-primary text-white p-2 rounded hover:bg-primary-dark">
                        Change Username
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ChangeUsernameModal;