// src/utils/tagGenerator.js
import axios from 'axios';

export const generateTags = async (taskText) => {
    try {
        const response = await axios.post('https://productivity-app.duckdns.org/api/generate', {
            model: 'llama3.1',
            prompt: `Generate 3 relevant hashtags for finding YouTube Shorts videos related to the following task: "${taskText}"
            Each tag should be a single word or short phrase, prefixed with #.
            Always include "#CleanWithMe" as one of the tags for cleaning-related tasks.
            Format your response as a valid JSON array of strings.
            Do not include any explanatory text or markdown formatting outside the JSON array.
            Focus on the most relevant tags to find applicable YouTube Shorts videos.`
        });

        let fullResponse = '';
        response.data.split('\n').forEach(line => {
            if (line.trim() !== '') {
                const parsed = JSON.parse(line);
                fullResponse += parsed.response;
            }
        });

        fullResponse = fullResponse.replace(/```json|```/g, '').trim();
        const tags = JSON.parse(fullResponse);

        if (!Array.isArray(tags) || tags.length !== 3) {
            throw new Error('Invalid tag format received from Llama');
        }

        return tags.map(tag => tag.replace('#', ''));
    } catch (error) {
        console.error('Error generating tags:', error);
        // Fallback to basic tag generation
        return taskText.toLowerCase().split(' ').filter(word => word.length > 3).slice(0, 4);
    }
};