// src/components/EnhancedAITaskBreakdown.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Loader2, AlertCircle, CheckCircle } from 'lucide-react';

const EnhancedAITaskBreakdown = ({ task, onUpdateTask }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [cooldown, setCooldown] = useState(0);

    useEffect(() => {
        let timer;
        if (cooldown > 0) {
            timer = setTimeout(() => setCooldown(cooldown - 1), 1000);
        }
        return () => clearTimeout(timer);
    }, [cooldown]);

    const breakdownTask = async () => {
        setIsLoading(true);
        setError(null);
        setSuccess(false);
        try {
            const response = await axios.post('https://productivity-app.duckdns.org/api/generate', {
                model: 'llama3.1',
                prompt: `Break down the following task into smaller, manageable subtasks: "${task.text}"
                Format your response as a valid JSON array of strings, each representing a subtask. Do not include any explanatory text or markdown formatting outside the JSON array.`,
            });

            let fullResponse = '';
            response.data.split('\n').forEach(line => {
                if (line.trim() !== '') {
                    const parsed = JSON.parse(line);
                    fullResponse += parsed.response;
                }
            });

            fullResponse = fullResponse.replace(/```json|```/g, '').trim();

            const subtasks = JSON.parse(fullResponse);

            if (!Array.isArray(subtasks)) {
                throw new Error('Ollama response is not an array');
            }

            // Initialize checklist as an empty array if it doesn't exist
            const existingChecklist = task.checklist || [];

            const newSubtasks = subtasks.filter(subtask =>
                !existingChecklist.some(existing => existing.text.toLowerCase() === subtask.toLowerCase())
            );

            if (newSubtasks.length === 0) {
                setSuccess(true);
                setCooldown(30); // Set a 30-second cooldown
                return; // Exit early if no new subtasks
            }

            const updatedTask = {
                ...task,
                checklist: [
                    ...existingChecklist,
                    ...newSubtasks.map(subtask => ({
                        id: Date.now() + Math.random(),
                        text: subtask,
                        completed: false
                    }))
                ]
            };

            onUpdateTask(updatedTask);
            setSuccess(true);
            setCooldown(30); // Set a 30-second cooldown
        } catch (err) {
            console.error('Error breaking down task:', err);
            setError('Failed to break down task. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const isDisabled = isLoading || cooldown > 0 || success;

    return (
        <div className="mt-4">
            <button
                onClick={breakdownTask}
                disabled={isDisabled}
                className={`text-white p-2 rounded flex items-center justify-center w-full ${
                    isDisabled ? 'bg-gray-400' : 'bg-blue-500 hover:bg-blue-600'
                }`}
            >
                {isLoading ? (
                    <>
                        <Loader2 className="animate-spin mr-2" />
                        Breaking down task...
                    </>
                ) : cooldown > 0 ? (
                    `Wait ${cooldown}s to break down again`
                ) : (
                    'AI Breakdown'
                )}
            </button>
            {error && (
                <div className="mt-2 p-2 bg-red-100 text-red-700 rounded flex items-center">
                    <AlertCircle className="mr-2" />
                    {error}
                </div>
            )}
            {success && (
                <div className="mt-2 p-2 bg-green-100 text-green-700 rounded flex items-center">
                    <CheckCircle className="mr-2" />
                    Task successfully broken down!
                </div>
            )}
        </div>
    );
};

export default EnhancedAITaskBreakdown;