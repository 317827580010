// src/ProductivityApp.js
import React, { useState, useEffect } from 'react';
import { database, auth } from './firebase';
import { ref, push, remove, update, onValue } from 'firebase/database';
import { updateProfile, updatePassword, signOut, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import { useAuth } from './AuthContext';
import { PlusCircle, Trash2, Edit, CheckCircle, Copy, Video, User, LogOut, Key, Clock, Loader2 } from 'lucide-react';
import TaskModal from './components/TaskModal';
import VideoFeed from './components/VideoFeed';
import ChangeUsernameModal from './components/ChangeUsernameModal';
import ChangePasswordModal from './components/ChangePasswordModal';
import PomodoroTimer from './components/PomodoroTimer';
import { useNavigate } from 'react-router-dom';
import { generateTags } from "./utils/tagGenerator";

const ProductivityApp = () => {
    const [tasks, setTasks] = useState([]);
    const [newTask, setNewTask] = useState('');
    const [filter, setFilter] = useState('all');
    const [selectedTask, setSelectedTask] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showVideoFeed, setShowVideoFeed] = useState(false);
    const [showProfileMenu, setShowProfileMenu] = useState(false);
    const [showPomodoroTimer, setShowPomodoroTimer] = useState(false);
    const [showChangeUsernameModal, setShowChangeUsernameModal] = useState(false);
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const { user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const tasksRef = ref(database, `tasks/${user.uid}`);
        const unsubscribe = onValue(tasksRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                const tasksArray = Object.entries(data).map(([id, task]) => ({ id, ...task }));
                setTasks(sortTasks(tasksArray));
                checkAndCreateRecurringTasks(tasksArray);
            } else {
                setTasks([]);
            }
        });

        return () => unsubscribe();
    }, [user]);

    const checkAndCreateRecurringTasks = (tasks) => {
        const today = new Date();
        tasks.forEach(task => {
            if (task.recurrence && task.nextOccurrence) {
                const nextOccurrence = new Date(task.nextOccurrence);
                if (nextOccurrence <= today) {
                    createNextRecurringTask(task);
                }
            }
        });
    };

    const createNextRecurringTask = (task) => {
        const { recurrence } = task;
        let nextDate = new Date(task.nextOccurrence);

        switch (recurrence.type) {
            case 'daily':
                nextDate.setDate(nextDate.getDate() + recurrence.interval);
                break;
            case 'weekly':
                nextDate.setDate(nextDate.getDate() + 7 * recurrence.interval);
                break;
            case 'monthly':
                nextDate.setMonth(nextDate.getMonth() + recurrence.interval);
                break;
            case 'yearly':
                nextDate.setFullYear(nextDate.getFullYear() + recurrence.interval);
                break;
            case 'custom':
                // Handle custom recurrence logic here
                break;
        }

        const newTask = {
            ...task,
            completed: false,
            createdAt: new Date().toISOString(),
            nextOccurrence: nextDate.toISOString()
        };
        delete newTask.id;

        const tasksRef = ref(database, `tasks/${user.uid}`);
        push(tasksRef, newTask);

        // Update the original recurring task's next occurrence
        const originalTaskRef = ref(database, `tasks/${user.uid}/${task.id}`);
        update(originalTaskRef, { nextOccurrence: nextDate.toISOString() });
    };

    const [isGeneratingTags, setIsGeneratingTags] = useState({});

    const addTask = async (taskText, recurrence = null) => {
        if (taskText.trim() !== '') {
            const tasksRef = ref(database, `tasks/${user.uid}`);
            const newTaskRef = push(tasksRef);
            const newTaskId = newTaskRef.key;

            const newTask = {
                id: newTaskId,
                text: taskText,
                completed: false,
                priority: 'medium',
                createdAt: new Date().toISOString(),
                description: '',
                checklist: [],
                comments: [],
                tags: []
            };

            if (recurrence) {
                newTask.recurrence = recurrence;
                newTask.nextOccurrence = calculateNextOccurrence(new Date(), recurrence).toISOString();
            }

            // Add the task to Firebase immediately
            await update(newTaskRef, newTask);

            // Start generating tags
            setIsGeneratingTags(prev => ({ ...prev, [newTaskId]: true }));

            try {
                const autoTags = await generateTags(taskText);
                // Update the task with generated tags
                await update(newTaskRef, { tags: autoTags });
            } catch (error) {
                console.error('Error generating tags:', error);
                // If tag generation fails, we'll leave the tags array empty
            } finally {
                setIsGeneratingTags(prev => ({ ...prev, [newTaskId]: false }));
            }

            setNewTask('');
        }
    };

    const calculateNextOccurrence = (date, recurrence) => {
        const nextDate = new Date(date);
        switch (recurrence.type) {
            case 'daily':
                nextDate.setDate(nextDate.getDate() + recurrence.interval);
                break;
            case 'weekly':
                nextDate.setDate(nextDate.getDate() + 7 * recurrence.interval);
                break;
            case 'monthly':
                nextDate.setMonth(nextDate.getMonth() + recurrence.interval);
                break;
            case 'yearly':
                nextDate.setFullYear(nextDate.getFullYear() + recurrence.interval);
                break;
            case 'custom':
                // Handle custom recurrence logic here
                break;
        }
        return nextDate;
    };

    const deleteTask = (id) => {
        const taskRef = ref(database, `tasks/${user.uid}/${id}`);
        remove(taskRef);
    };

    const toggleComplete = (id) => {
        const taskRef = ref(database, `tasks/${user.uid}/${id}`);
        const task = tasks.find(t => t.id === id);
        update(taskRef, { completed: !task.completed });
    };

    const changePriority = (id, priority) => {
        const taskRef = ref(database, `tasks/${user.uid}/${id}`);
        update(taskRef, { priority });
    };

    const openTaskModal = (task) => {
        setSelectedTask(task);
        setIsModalOpen(true);
    };

    const closeTaskModal = () => {
        setSelectedTask(null);
        setIsModalOpen(false);
    };

    const updateTask = (updatedTask) => {
        const taskRef = ref(database, `tasks/${user.uid}/${updatedTask.id}`);
        const taskToUpdate = { ...updatedTask };

        // Remove undefined or null values
        Object.keys(taskToUpdate).forEach(key =>
            (taskToUpdate[key] === undefined || taskToUpdate[key] === null) && delete taskToUpdate[key]
        );

        // If recurrence exists, ensure all its properties are defined
        if (taskToUpdate.recurrence) {
            Object.keys(taskToUpdate.recurrence).forEach(key =>
                (taskToUpdate.recurrence[key] === undefined || taskToUpdate.recurrence[key] === null) && delete taskToUpdate.recurrence[key]
            );
        }

        update(taskRef, taskToUpdate);
        closeTaskModal();
    };

    const duplicateTask = (task) => {
        const tasksRef = ref(database, `tasks/${user.uid}`);
        const newTask = {
            ...task,
            text: `${task.text} (Copy)`,
            createdAt: new Date().toISOString(),
            tags: [...task.tags]
        };
        delete newTask.id; // Remove the id so Firebase generates a new one
        push(tasksRef, newTask);
    };

    const sortTasks = (tasksToSort) => {
        const priorityOrder = { high: 1, medium: 2, low: 3 };
        return tasksToSort.sort((a, b) =>
            priorityOrder[a.priority] - priorityOrder[b.priority] ||
            new Date(b.createdAt) - new Date(a.createdAt)
        );
    };

    const filteredTasks = tasks.filter(task => {
        if (filter === 'active') return !task.completed;
        if (filter === 'completed') return task.completed;
        return true;
    });

    const priorityColors = {
        low: 'bg-green-500',
        medium: 'bg-yellow-500',
        high: 'bg-red-500'
    };

    const handleSignOut = async () => {
        try {
            await signOut(auth);
            navigate('/login');
        } catch (error) {
            console.error("Error signing out: ", error);
        }
    };

    const handleChangeUsername = async (newUsername) => {
        try {
            await updateProfile(auth.currentUser, { displayName: newUsername });
            // Update the username in your database if you're storing it there
            const userRef = ref(database, `users/${user.uid}`);
            await update(userRef, { username: newUsername });
            setShowChangeUsernameModal(false);
        } catch (error) {
            throw new Error("Failed to update username: " + error.message);
        }
    };

    const handleChangePassword = async (currentPassword, newPassword) => {
        try {
            const credential = EmailAuthProvider.credential(user.email, currentPassword);
            await reauthenticateWithCredential(user, credential);
            await updatePassword(user, newPassword);
            setShowChangePasswordModal(false);
        } catch (error) {
            throw new Error("Failed to update password: " + error.message);
        }
    };

    return (
        <div className="bg-background min-h-screen p-4">
            <div className="container mx-auto">
                <header className="flex justify-between items-center mb-8">
                    <h1 className="text-3xl font-bold text-gray-900">
                        Hello {user.displayName || user.email}!
                    </h1>
                    <div className="flex items-center space-x-2">
                        <button
                            onClick={() => setShowVideoFeed(!showVideoFeed)}
                            className={`p-2 rounded transition-colors duration-200 ${
                                showVideoFeed
                                    ? "bg-primary text-white hover:bg-primary-dark"
                                    : "bg-gray-300 text-gray-600 hover:bg-gray-400"
                            }`}
                            aria-label={showVideoFeed ? "Disable video feed" : "Enable video feed"}
                        >
                            <Video className="w-5 h-5"/>
                        </button>
                        <button
                            onClick={() => setShowPomodoroTimer(true)}
                            className="p-2 rounded bg-primary text-white hover:bg-primary-dark"
                            aria-label="Open Pomodoro Timer"
                        >
                            <Clock className="w-5 h-5"/>
                        </button>
                        <div className="relative">
                            <button
                                onClick={() => setShowProfileMenu(!showProfileMenu)}
                                className="p-2 rounded bg-primary text-white hover:bg-primary-dark"
                            >
                                <User className="w-5 h-5"/>
                            </button>
                            {showProfileMenu && (
                                <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
                                    <button
                                        onClick={() => setShowChangeUsernameModal(true)}
                                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                                    >
                                        <Key className="w-4 h-4 inline-block mr-2"/>
                                        Change Username
                                    </button>
                                    <button
                                        onClick={() => setShowChangePasswordModal(true)}
                                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                                    >
                                        <Key className="w-4 h-4 inline-block mr-2"/>
                                        Change Password
                                    </button>
                                    <button
                                        onClick={handleSignOut}
                                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                                    >
                                        <LogOut className="w-4 h-4 inline-block mr-2"/>
                                        Sign Out
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </header>

                <div className="mb-8">
                    <div className="relative">
                        <input
                            type="text"
                            value={newTask}
                            onChange={(e) => setNewTask(e.target.value)}
                            onKeyPress={(e) => e.key === 'Enter' && addTask(newTask)}
                            placeholder="What do you want to do today?"
                            className="w-full p-2 pr-10 rounded border border-gray-300 bg-white text-gray-900"
                        />
                        <button
                            onClick={() => addTask(newTask)}
                            className="absolute right-2 top-1/2 transform -translate-y-1/2 text-primary"
                        >
                            <PlusCircle className="w-6 h-6"/>
                        </button>
                    </div>
                </div>

                <div className="mb-4 flex space-x-2">
                    <button
                        onClick={() => setFilter('all')}
                        className={`px-3 py-1 rounded ${filter === 'all' ? 'bg-primary text-white' : 'bg-gray-200 text-gray-700'}`}
                    >
                        All
                    </button>
                    <button
                        onClick={() => setFilter('active')}
                        className={`px-3 py-1 rounded ${filter === 'active' ? 'bg-primary text-white' : 'bg-gray-200 text-gray-700'}`}
                    >
                        Active
                    </button>
                    <button
                        onClick={() => setFilter('completed')}
                        className={`px-3 py-1 rounded ${filter === 'completed' ? 'bg-primary text-white' : 'bg-gray-200 text-gray-700'}`}
                    >
                        Completed
                    </button>
                </div>

                <div className="space-y-4">
                    {filteredTasks.map(task => (
                        <div key={task.id} className="flex items-center justify-between p-2 border rounded bg-white">
                        <span
                            className={`flex-grow cursor-pointer ${task.completed ? 'line-through text-gray-500' : 'text-gray-900'}`}
                            onClick={() => openTaskModal(task)}
                        >
                            {task.text}
                            {isGeneratingTags[task.id] ? (
                                <Loader2 className="inline ml-2 w-4 h-4 animate-spin"/>
                            ) : (
                                task.tags && task.tags.length > 0 && (
                                    <span className="ml-2 text-sm text-gray-500">
                                        {task.tags.map(tag => `#${tag}`).join(' ')}
                                    </span>
                                )
                            )}
                        </span>
                            <div className="flex items-center space-x-2">
                                <select
                                    value={task.priority}
                                    onChange={(e) => changePriority(task.id, e.target.value)}
                                    className={`p-1 rounded border text-white ${priorityColors[task.priority]}`}
                                >
                                    <option value="low">Low</option>
                                    <option value="medium">Medium</option>
                                    <option value="high">High</option>
                                </select>
                                <button onClick={() => toggleComplete(task.id)}>
                                    <CheckCircle
                                        className={`w-5 h-5 ${task.completed ? 'text-green-500' : 'text-gray-400'}`}/>
                                </button>
                                <button onClick={() => openTaskModal(task)}>
                                    <Edit className="w-5 h-5 text-blue-500"/>
                                </button>
                                <button onClick={() => duplicateTask(task)}>
                                    <Copy className="w-5 h-5 text-purple-500"/>
                                </button>
                                <button onClick={() => deleteTask(task.id)}>
                                    <Trash2 className="w-5 h-5 text-red-500"/>
                                </button>
                            </div>
                        </div>
                    ))}
                </div>

                {isModalOpen && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4">
                        <div
                            className="bg-white rounded-lg w-full max-w-6xl max-h-[90vh] overflow-hidden flex flex-col md:flex-row">
                            <div className="w-full md:w-2/3 p-6 overflow-y-auto">
                                <TaskModal
                                    task={selectedTask}
                                    onClose={closeTaskModal}
                                    onUpdate={updateTask}
                                    onDelete={deleteTask}
                                />
                            </div>
                            {showVideoFeed && (
                                <div className="w-full md:w-1/3 bg-gray-800 overflow-hidden">
                                    <VideoFeed taskTags={selectedTask?.tags || []}/>
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {showChangeUsernameModal && (
                    <ChangeUsernameModal
                        onClose={() => setShowChangeUsernameModal(false)}
                        onChangeUsername={handleChangeUsername}
                    />
                )}

                {showChangePasswordModal && (
                    <ChangePasswordModal
                        onClose={() => setShowChangePasswordModal(false)}
                        onChangePassword={handleChangePassword}
                    />
                )}

                {showPomodoroTimer && (
                    <PomodoroTimer
                        tasks={tasks}
                        onClose={() => setShowPomodoroTimer(false)}
                        onAddTask={addTask}
                    />
                )}
            </div>
        </div>
    );
};

export default ProductivityApp;