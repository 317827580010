import React, { useState, useEffect, useCallback } from 'react';
import { Play, Pause, SkipForward, RotateCcw, X, Plus } from 'lucide-react';

const POMODORO = 'pomodoro';
const SHORT_BREAK = 'shortBreak';
const LONG_BREAK = 'longBreak';

const defaultTimes = {
    [POMODORO]: 25 * 60,
    [SHORT_BREAK]: 5 * 60,
    [LONG_BREAK]: 15 * 60
};

const quotes = [
    "I am the hero of my own life.",
    "Focus on being productive instead of busy. - Tim Ferriss",
    "The way to get started is to quit talking and begin doing. - Walt Disney",
    "It's not about time, it's about choices. How are you spending your choices? - Beverly Adamo",
    "Until we can manage time, we can manage nothing else. - Peter Drucker",
    "Productivity is never an accident. It is always the result of a commitment to excellence, intelligent planning, and focused effort. - Paul J. Meyer",
    "The only way around is through. - Robert Frost",
    "Don't watch the clock; do what it does. Keep going. - Sam Levenson",
    "Your mind is for having ideas, not holding them. - David Allen",
    "The secret of getting ahead is getting started. - Mark Twain"
];

const PomodoroTimer = ({ tasks, onClose, onAddTask }) => {
    const [currentTask, setCurrentTask] = useState('');
    const [timeLeft, setTimeLeft] = useState(defaultTimes[POMODORO]);
    const [timerType, setTimerType] = useState(POMODORO);
    const [isActive, setIsActive] = useState(false);
    const [pomodoroCount, setPomodoroCount] = useState(0);
    const [breakCount, setBreakCount] = useState(0);
    const [longBreakCount, setLongBreakCount] = useState(0);
    const [customTimes, setCustomTimes] = useState(defaultTimes);
    const [quote, setQuote] = useState(quotes[0]);
    const [newTaskInput, setNewTaskInput] = useState('');

    const resetTimer = useCallback(() => {
        setTimeLeft(customTimes[timerType]);
        setIsActive(false);
    }, [customTimes, timerType]);

    useEffect(() => {
        resetTimer();
    }, [timerType, resetTimer]);

    useEffect(() => {
        let interval = null;
        if (isActive && timeLeft > 0) {
            interval = setInterval(() => {
                setTimeLeft(timeLeft => timeLeft - 1);
            }, 1000);
        } else if (isActive && timeLeft === 0) {
            handleTimerComplete();
        }
        return () => clearInterval(interval);
    }, [isActive, timeLeft]);

    useEffect(() => {
        // Change quote every 5 minutes
        const quoteInterval = setInterval(() => {
            setQuote(quotes[Math.floor(Math.random() * quotes.length)]);
        }, 5 * 60 * 1000);
        return () => clearInterval(quoteInterval);
    }, []);

    const handleTimerComplete = () => {
        if (timerType === POMODORO) {
            setPomodoroCount(count => count + 1);
            if ((pomodoroCount + 1) % 4 === 0) {
                setTimerType(LONG_BREAK);
                setLongBreakCount(count => count + 1);
            } else {
                setTimerType(SHORT_BREAK);
                setBreakCount(count => count + 1);
            }
        } else {
            setTimerType(POMODORO);
        }
        setIsActive(false);
        if (!document.hasFocus()) {
            new Notification('Pomodoro Timer', {
                body: `${timerType === POMODORO ? 'Break' : 'Pomodoro'} session completed!`
            });
        }
    };

    const toggleTimer = () => setIsActive(!isActive);

    const skipSession = () => {
        handleTimerComplete();
        resetTimer();
    };

    const handleQuoteClick = () => {
        const newQuote = prompt('Enter a new quote:', quote);
        if (newQuote) setQuote(newQuote);
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const handleNewTask = () => {
        if (newTaskInput.trim() !== '') {
            onAddTask(newTaskInput);
            setNewTaskInput('');
            setCurrentTask(newTaskInput);
        }
    };

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
            <div className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full relative">
                <button onClick={onClose} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
                    <X className="w-6 h-6" />
                </button>
                <h2 className="text-2xl font-bold mb-4">Pomodoro Timer</h2>
                <div className="relative mb-4">
                    <select
                        value={currentTask}
                        onChange={(e) => setCurrentTask(e.target.value)}
                        className="w-full p-2 border rounded appearance-none bg-white"
                    >
                        <option value="" disabled hidden>What do you want to focus on?</option>
                        {tasks.map(task => (
                            <option key={task.id} value={task.id}>{task.text}</option>
                        ))}
                        <option value="new">New Task</option>
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                    </div>
                </div>
                {currentTask === 'new' && (
                    <div className="mb-4 flex">
                        <input
                            type="text"
                            value={newTaskInput}
                            onChange={(e) => setNewTaskInput(e.target.value)}
                            placeholder="Enter new task"
                            className="flex-grow p-2 border rounded-l"
                        />
                        <button onClick={handleNewTask} className="bg-blue-500 text-white p-2 rounded-r hover:bg-blue-600">
                            <Plus className="w-5 h-5" />
                        </button>
                    </div>
                )}
                <div className="text-6xl font-bold text-center mb-4">{formatTime(timeLeft)}</div>
                <div className="flex justify-center space-x-4 mb-4">
                    <button onClick={toggleTimer} className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600">
                        {isActive ? <Pause className="w-6 h-6" /> : <Play className="w-6 h-6" />}
                    </button>
                    <button onClick={resetTimer} className="bg-gray-300 text-gray-700 p-2 rounded hover:bg-gray-400">
                        <RotateCcw className="w-6 h-6" />
                    </button>
                    <button onClick={skipSession} className="bg-gray-300 text-gray-700 p-2 rounded hover:bg-gray-400">
                        <SkipForward className="w-6 h-6" />
                    </button>
                </div>
                <div className="flex justify-between mb-4">
                    <span>Pomodoros: {pomodoroCount}</span>
                    <span>Breaks: {breakCount}</span>
                    <span>Long Breaks: {longBreakCount}</span>
                </div>
                <div className="mb-4">
                    <h3 className="font-bold mb-2">Customize Times (minutes):</h3>
                    <div className="flex justify-between">
                        {Object.entries(customTimes).map(([key, value]) => (
                            <input
                                key={key}
                                type="number"
                                value={Math.floor(value / 60)}
                                onChange={(e) => setCustomTimes({...customTimes, [key]: e.target.value * 60})}
                                className="w-20 p-1 border rounded"
                            />
                        ))}
                    </div>
                </div>
                <p className="text-center italic mb-4 cursor-pointer" onClick={handleQuoteClick}>{quote}</p>
            </div>
        </div>
    );
};

export default PomodoroTimer;