import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { ChevronUp, ChevronDown } from 'lucide-react';

const API_KEY = process.env.REACT_APP_YOUTUBE_API_KEY;
const BASE_URL = 'https://www.googleapis.com/youtube/v3/search';

const VideoFeed = ({ taskTags }) => {
    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const videoContainerRef = useRef(null);
    const cache = useRef({});
    const CACHE_EXPIRATION = 1000 * 60 * 5; // 5 minutes

    const fetchVideos = useCallback(async (tags) => {
        const cacheKey = tags.join(',');
        const cachedResult = cache.current[cacheKey];

        if (cachedResult && Date.now() - cachedResult.timestamp < CACHE_EXPIRATION) {
            setVideos(cachedResult.videos);
            return;
        }

        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(BASE_URL, {
                params: {
                    part: 'snippet',
                    q: tags.join(' ') + ' #shorts',
                    type: 'video',
                    maxResults: 3,
                    videoDuration: 'short',
                    key: API_KEY
                }
            });
            const shortsVideos = response.data.items.filter(video =>
                video.snippet.thumbnails.high.height > video.snippet.thumbnails.high.width
            );
            const resultVideos = shortsVideos.length > 0 ? shortsVideos : response.data.items;
            setVideos(resultVideos);
            cache.current[cacheKey] = { videos: resultVideos, timestamp: Date.now() };
        } catch (err) {
            setError('Failed to fetch videos. Please try again later.');
            console.error('Error fetching videos:', err);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (taskTags.length > 0) {
            fetchVideos(taskTags);
        }
    }, [taskTags, fetchVideos]);

    const scrollToNextVideo = (direction) => {
        setCurrentVideoIndex((prevIndex) => {
            if (direction === 'up') {
                return prevIndex > 0 ? prevIndex - 1 : videos.length - 1;
            } else {
                return (prevIndex + 1) % videos.length;
            }
        });
    };

    useEffect(() => {
        if (videoContainerRef.current) {
            videoContainerRef.current.scrollTop = 0;
        }
    }, [currentVideoIndex]);

    if (loading) return <div className="text-white">Loading videos...</div>;
    if (error) return <div className="text-white">{error}</div>;

    return (
        <div className="video-feed h-full flex flex-col bg-gray-800 rounded-lg overflow-hidden">
            <h2 className="text-2xl font-bold mb-4 text-white p-4">Related Videos</h2>
            <div className="flex-grow relative" ref={videoContainerRef}>
                {videos.length > 0 && (
                    <div className="video-item h-full flex flex-col justify-center">
                        <iframe
                            width="100%"
                            height="100%"
                            src={`https://www.youtube.com/embed/${videos[currentVideoIndex].id.videoId}?autoplay=1`}
                            title={videos[currentVideoIndex].snippet.title}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                        <div className="p-4 bg-gray-700">
                            <h3 className="text-lg font-semibold text-white mb-2">{videos[currentVideoIndex].snippet.title}</h3>
                            <p className="text-gray-300 text-sm">{videos[currentVideoIndex].snippet.description}</p>
                        </div>
                    </div>
                )}
            </div>
            <div className="flex justify-between p-2 bg-gray-700">
                <button
                    onClick={() => scrollToNextVideo('up')}
                    className="p-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                    <ChevronUp className="w-6 h-6" />
                </button>
                <button
                    onClick={() => scrollToNextVideo('down')}
                    className="p-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                    <ChevronDown className="w-6 h-6" />
                </button>
            </div>
        </div>
    );
};

export default VideoFeed;